@if (notifications.length > 0) {
  <div class="title-s fw-300 ml-16 mb-8">
    {{ title }}
  </div>
  <div class="divider mr-16 ml-16"></div>
  @for (notification of notifications; track notification.id) {
    @if (notification.id) {
      <div class="flex gap-12 mb-16 mr-16 ml-16">
        <ie-card>
          <div card-body>
            <a
              tabindex="0"
              (click)="markAsRead(notification)"
              (keydown)="markAsRead(notification)"
            >
              <div class="col-12 p-4" nz-row>
                <div class="col-12" nz-col>
                  <div
                    class="ml-16"
                    [class]="notification.inappStatus !== 'READ' ? 'dot' : ''"
                  ></div>
                </div>
                <div class="col-12" nz-col [nzSpan]="1">
                  <nz-avatar nzSize="large" class="mt-8"></nz-avatar>
                </div>
                <div class="col-12" nz-col [nzSpan]="22">
                  <p class="t-align-j flex-col">
                    {{
                      notification.sender
                        ? notification.sender.firstName
                        : notification.receiver.firstName
                    }}
                    {{
                      notification.sender
                        ? notification.sender.lastName
                        : notification.receiver.lastName
                    }}
                  </p>
                  <p
                    class="t-align-j flex-col"
                    [innerHTML]="notification.inappMsg"
                  ></p>
                  <span class="mt-4 flex-col"
                    ><small>{{ notification.created | timeago }}</small></span
                  >
                </div>
              </div>
            </a>
          </div>
        </ie-card>
      </div>
    }
  }
}
