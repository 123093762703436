export enum LayoutState {
  OPENED = 'opened',
  CLOSED = 'closed',
}

export enum LayoutBg {
  NORMAL_BG = 'NORMAL_BG',
  LIGHT_BG = 'LIGHT_BG',
  DARK_BG = 'DARK_BG',
}
