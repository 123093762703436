import { Component, Input } from '@angular/core';
import { FormErrorPipe } from '@ieShared/pipes/form-error.pipe';
import { IeAlertComponent } from '@ieShared/components/ie-alert/ie-alert.component';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import { IeFormErrorComponent } from '@ieShared/components/ie-form-error/ie-form-error.component';
import { IeIconsComponent } from '@ieShared/components/ie-icons/ie-icons.component';
import {
  NzInputDirective,
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
} from 'ng-zorro-antd/input';
import { PasswordStrengthComponent } from '@ieShared/components/password-strength/password-strength.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormError } from '@ieCore/enums/form-error';

@Component({
  selector: 'app-reset-pass-group',
  standalone: true,
  imports: [
    FormErrorPipe,
    IeAlertComponent,
    IeButtonComponent,
    IeFormErrorComponent,
    IeIconsComponent,
    NzInputDirective,
    NzInputGroupComponent,
    NzInputGroupWhitSuffixOrPrefixDirective,
    PasswordStrengthComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './reset-pass-group.component.html',
  styleUrl: './reset-pass-group.component.scss',
})
export class ResetPassGroupComponent {
  @Input() fg!: FormGroup;
  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() showPasswordStrength: boolean = true;
  formErrors = FormError;
  showPasswordAsText1 = false;
  showPasswordAsText2 = false;
  showPasswordAsText3 = false;
}
